#checkbox-component.checkbox, #checkbox-component.checkbox-check, #checkbox-component.checkbox-pill, #checkbox-component.checkbox-pill-check, #checkbox-component.checkbox-full {
  width: 100%;
}
#checkbox-component.checkbox .checkbox-title, #checkbox-component.checkbox-check .checkbox-title, #checkbox-component.checkbox-pill .checkbox-title, #checkbox-component.checkbox-pill-check .checkbox-title, #checkbox-component.checkbox-full .checkbox-title {
  margin: 20px 0;
  position: relative;
}
#checkbox-component.checkbox .checkbox-content > .checkbox-option label, #checkbox-component.checkbox-check .checkbox-content > .checkbox-option label, #checkbox-component.checkbox-pill .checkbox-content > .checkbox-option label, #checkbox-component.checkbox-pill-check .checkbox-content > .checkbox-option label, #checkbox-component.checkbox-full .checkbox-content > .checkbox-option label {
  margin-left: 10px;
}
#checkbox-component.checkbox-full .checkbox-option {
  background-color: #eee;
  border: solid 1px #ddd;
  height: 20px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  font-size: 11px;
}
