#ces-component {
  /*CES Design*/
}
#ces-component.ces, #ces-component.ces-effort {
  width: 100%;
}
#ces-component.ces .ces-title, #ces-component.ces-effort .ces-title {
  margin: 20px 0;
  position: relative;
}
#ces-component.ces .ces-item {
  display: block;
  width: calc(100% - 4px);
  margin: 2px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #222;
  font-weight: bold;
  font-size: 14px;
  border: solid 2px #fff;
}
#ces-component.ces .ces-item.option-color-0 {
  background-color: #dcdcdc;
}
#ces-component.ces .ces-item.option-color-1 {
  background-color: #ffc8c9;
}
#ces-component.ces .ces-item.option-color-2 {
  background-color: #ffe1bd;
}
#ces-component.ces .ces-item.option-color-3 {
  background-color: #fff0c1;
}
#ces-component.ces .ces-item.option-color-4 {
  background-color: #fbffc0;
}
#ces-component.ces .ces-item.option-color-5 {
  background-color: #d4ffbe;
}
#ces-component.ces-effort .ces-effort-box {
  position: relative;
  overflow: hidden;
}
#ces-component.ces-effort .ces-effort-box .ces-effort-text {
  width: 100%;
  padding: 2px;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  color: #666;
}
#ces-component.ces-effort .ces-effort-box .ces-effort-text .right {
  float: right;
}
#ces-component.ces-effort .ces-effort-box .ces-item {
  float: left;
  width: 14.2828282828%;
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 35px;
  color: #222;
  font-weight: bold;
  font-size: 14px;
}
#ces-component.ces-effort .ces-effort-box .ces-item .number {
  height: 35px;
}
#ces-component.ces-effort .ces-effort-box .ces-item svg {
  font-size: 24px;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-7 {
  background-color: #ff0d27;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-6 {
  background-color: #ff8c00;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-5 {
  background-color: #ffc004;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-4 {
  background-color: #ffff00;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-3 {
  background-color: #e7e63a;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-2 {
  background-color: #72e73a;
  color: #fff;
}
#ces-component.ces-effort .ces-effort-box .ces-item.ces-color-1 {
  background-color: #329500;
  color: #fff;
}
