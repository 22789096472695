#nps-component {
  /*NPS Design*/
}
#nps-component.nps, #nps-component.nps-colorfull, #nps-component.nps-triade {
  width: 100%;
}
#nps-component.nps .nps-title, #nps-component.nps-colorfull .nps-title, #nps-component.nps-triade .nps-title {
  margin: 0 10px;
  position: relative;
}
#nps-component.nps .item, #nps-component.nps-colorfull .item, #nps-component.nps-triade .item {
  display: inline-block;
  width: 8.0909090909%;
  margin: 0.5%;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 38px;
  color: #222;
  font-weight: bold;
}
#nps-component.nps .item {
  border: solid 1px #949494;
}
#nps-component.nps .item.selected {
  background-color: #222;
  color: white;
}
#nps-component.nps-colorfull .item {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
#nps-component.nps-colorfull .item.option-color-0 {
  background-color: #ffc8c9;
}
#nps-component.nps-colorfull .item.option-color-1 {
  background-color: #ffc8c8;
}
#nps-component.nps-colorfull .item.option-color-2 {
  background-color: #ffd5c9;
}
#nps-component.nps-colorfull .item.option-color-3 {
  background-color: #ffddc8;
}
#nps-component.nps-colorfull .item.option-color-4 {
  background-color: #ffeacc;
}
#nps-component.nps-colorfull .item.option-color-5 {
  background-color: #fff2cc;
}
#nps-component.nps-colorfull .item.option-color-6 {
  background-color: #fff7c9;
}
#nps-component.nps-colorfull .item.option-color-7 {
  background-color: #fcffcb;
}
#nps-component.nps-colorfull .item.option-color-8 {
  background-color: #f3ffc7;
}
#nps-component.nps-colorfull .item.option-color-9 {
  background-color: #e9ffc7;
}
#nps-component.nps-colorfull .item.option-color-10 {
  background-color: #d9ffc5;
}
#nps-component.nps-triade .item {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
#nps-component.nps-triade .item.option-color-0, #nps-component.nps-triade .item.option-color-1, #nps-component.nps-triade .item.option-color-2, #nps-component.nps-triade .item.option-color-3, #nps-component.nps-triade .item.option-color-4, #nps-component.nps-triade .item.option-color-5, #nps-component.nps-triade .item.option-color-6 {
  background-color: #ffc8c9;
}
#nps-component.nps-triade .item.option-color-7, #nps-component.nps-triade .item.option-color-8 {
  background-color: #fff2cc;
}
#nps-component.nps-triade .item.option-color-9, #nps-component.nps-triade .item.option-color-10 {
  background-color: #d9ffc5;
}
