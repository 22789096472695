#radiobox-component.radio, #radiobox-component.radio-check, #radiobox-component.radio-pill, #radiobox-component.radio-pill-check, #radiobox-component.radio-full {
  width: 100%;
}
#radiobox-component.radio .radiobox-title, #radiobox-component.radio-check .radiobox-title, #radiobox-component.radio-pill .radiobox-title, #radiobox-component.radio-pill-check .radiobox-title, #radiobox-component.radio-full .radiobox-title {
  margin: 20px 0;
  position: relative;
}
#radiobox-component.radio label, #radiobox-component.radio-check label, #radiobox-component.radio-pill label, #radiobox-component.radio-pill-check label, #radiobox-component.radio-full label {
  margin-left: 10px;
}
#radiobox-component.radio-full .radiobox-option {
  background-color: #eee;
  border: solid 1px #ddd;
  height: 20px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  font-size: 11px;
}
