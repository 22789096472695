#auto-complete-component.auto-complete {
  width: 100%;
}
#auto-complete-component.auto-complete .auto-complete-title {
  margin: 20px 0;
  position: relative;
}
#auto-complete-component.auto-complete .auto-complete-content {
  margin: 10px 0;
  width: 100%;
}
#auto-complete-component.auto-complete .auto-complete-content input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
