#input-component {
  /*cascading-dropdown design*/
}
#input-component.text, #input-component.email, #input-component.tel, #input-component.email, #input-component.cpf, #input-component.date, #input-component.time, #input-component.datetime-local {
  width: 100%;
}
#input-component.text .input-question, #input-component.email .input-question, #input-component.tel .input-question, #input-component.email .input-question, #input-component.cpf .input-question, #input-component.date .input-question, #input-component.time .input-question, #input-component.datetime-local .input-question {
  margin: 20px 0;
  position: relative;
}
#input-component.text .input-question > span.require, #input-component.email .input-question > span.require, #input-component.tel .input-question > span.require, #input-component.email .input-question > span.require, #input-component.cpf .input-question > span.require, #input-component.date .input-question > span.require, #input-component.time .input-question > span.require, #input-component.datetime-local .input-question > span.require {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
  color: red;
}
#input-component.text .input-content, #input-component.email .input-content, #input-component.tel .input-content, #input-component.email .input-content, #input-component.cpf .input-content, #input-component.date .input-content, #input-component.time .input-content, #input-component.datetime-local .input-content {
  margin: 10px 0;
  width: 100%;
}
#input-component.text .input-content input,
#input-component.text .input-content select, #input-component.email .input-content input,
#input-component.email .input-content select, #input-component.tel .input-content input,
#input-component.tel .input-content select, #input-component.email .input-content input,
#input-component.email .input-content select, #input-component.cpf .input-content input,
#input-component.cpf .input-content select, #input-component.date .input-content input,
#input-component.date .input-content select, #input-component.time .input-content input,
#input-component.time .input-content select, #input-component.datetime-local .input-content input,
#input-component.datetime-local .input-content select {
  width: 100%;
  display: block;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#input-component.text .input-content input:focus,
#input-component.text .input-content select:focus, #input-component.email .input-content input:focus,
#input-component.email .input-content select:focus, #input-component.tel .input-content input:focus,
#input-component.tel .input-content select:focus, #input-component.email .input-content input:focus,
#input-component.email .input-content select:focus, #input-component.cpf .input-content input:focus,
#input-component.cpf .input-content select:focus, #input-component.date .input-content input:focus,
#input-component.date .input-content select:focus, #input-component.time .input-content input:focus,
#input-component.time .input-content select:focus, #input-component.datetime-local .input-content input:focus,
#input-component.datetime-local .input-content select:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
#input-component.text .input-content .magic-buttons, #input-component.email .input-content .magic-buttons, #input-component.tel .input-content .magic-buttons, #input-component.email .input-content .magic-buttons, #input-component.cpf .input-content .magic-buttons, #input-component.date .input-content .magic-buttons, #input-component.time .input-content .magic-buttons, #input-component.datetime-local .input-content .magic-buttons {
  width: 100%;
}
#input-component.text .input-content .magic-buttons span, #input-component.email .input-content .magic-buttons span, #input-component.tel .input-content .magic-buttons span, #input-component.email .input-content .magic-buttons span, #input-component.cpf .input-content .magic-buttons span, #input-component.date .input-content .magic-buttons span, #input-component.time .input-content .magic-buttons span, #input-component.datetime-local .input-content .magic-buttons span {
  border: solid 1px #ccc;
  border-radius: 5px;
  display: inline-block;
  width: 31.333333333%;
  padding: 5px;
  margin: 1%;
  text-align: center;
  cursor: pointer;
  color: #888;
  font-weight: bold;
  font-size: 12px;
}
#input-component.text .input-content .magic-buttons span:hover, #input-component.email .input-content .magic-buttons span:hover, #input-component.tel .input-content .magic-buttons span:hover, #input-component.email .input-content .magic-buttons span:hover, #input-component.cpf .input-content .magic-buttons span:hover, #input-component.date .input-content .magic-buttons span:hover, #input-component.time .input-content .magic-buttons span:hover, #input-component.datetime-local .input-content .magic-buttons span:hover {
  background-color: #f5f5f5;
}
#input-component.text .input-error, #input-component.email .input-error, #input-component.tel .input-error, #input-component.email .input-error, #input-component.cpf .input-error, #input-component.date .input-error, #input-component.time .input-error, #input-component.datetime-local .input-error {
  margin: 20px 0;
  color: red;
  font-weight: bold;
}
