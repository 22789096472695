#mosaic-component.mosaic {
  width: 100%;
}
#mosaic-component.mosaic .mosaic-title {
  margin: 20px 0;
  position: relative;
}
#mosaic-component.mosaic .mosaic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
