@keyframes activemenu {
  from {
    left: -320px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
@keyframes inactivemenu {
  from {
    left: 0px;
    opacity: 1;
  }
  to {
    left: -320px;
    opacity: 0;
  }
}
#box-menu-main {
  position: relative;
}
#box-menu-main a {
  display: flex;
  width: 100%;
  font-size: 13px;
  position: relative;
  float: left;
  top: 0;
  left: 0;
  padding: 10px;
  text-decoration: none;
  outline: none;
  color: #000;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
#box-menu-main a > span.label {
  text-align: center;
}
#box-menu-main a > span.icon {
  text-align: center;
}
#box-menu-main a > span.icon svg {
  font-size: 22px;
}
#box-menu-main a:hover {
  font-weight: bold;
  background-color: #eee;
  color: var(--color-inovyo-dark);
}
#box-menu-main a:active {
  font-weight: bold;
  color: #000;
}

header#header-inovyo {
  height: 60px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}
header#header-inovyo .main-menu {
  width: 60px;
  height: 60px;
  float: left;
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
header#header-inovyo .main-menu > .sticks {
  width: 30px;
  height: 4px;
  background-color: black;
  margin: auto;
  border-radius: 4px;
}
header#header-inovyo .main-menu > .sticks::before {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  background-color: black;
  margin: -8px auto 0 auto;
  border-radius: 4px;
}
header#header-inovyo .main-menu > .sticks::after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  background-color: black;
  margin: 12px auto 0 auto;
  border-radius: 4px;
}
header#header-inovyo .logo-inovyo {
  width: 100px;
  height: 60px;
  display: block;
  margin: auto;
}
header#header-inovyo .logo-inovyo img {
  margin-top: 12px;
  width: 100px;
}
header#header-inovyo div.language-box {
  position: absolute;
  float: right;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
header#header-inovyo div.language-box > img {
  width: 30px;
}
