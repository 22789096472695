#login-page {
  height: calc(100vh - 70px);
  width: 100%;
  float: left;
  background-image: url("../../images/undraw_button_style_re_uctt.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-page .login-box {
  width: 320px;
  max-height: 350px;
}
#login-page .login-box .login-google {
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#login-page .login-box .login-google > img {
  height: 20px;
}
#login-page .login-box .login-google > a {
  text-decoration: none;
  outline: none;
  color: #444;
  font-weight: 600;
  margin-left: 10px;
}

@media all and (max-width: 560px) {
  #login-page {
    background: none;
  }
}
body.christmas #login-page {
  background: none;
}
