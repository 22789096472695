#template-builder > div:nth-child(2) {
  height: calc(100vh - 72px) !important;
}
#template-builder > div.export {
  position: absolute;
  float: left;
  bottom: 19px;
  left: 135px;
  z-index: 100;
  background-color: #293039;
  height: 40px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#template-content-html {
  max-height: 500px;
  overflow-y: auto;
}
