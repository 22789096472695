#dropdown-component {
  /*NPS Design*/
}
#dropdown-component.dropdown {
  width: 100%;
}
#dropdown-component.dropdown .dropdown-title {
  margin: 20px 0;
  position: relative;
}
#dropdown-component.dropdown .dropdown-content > .select {
  width: 100%;
  display: block;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #ccc;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f5f5f5;
}
#dropdown-component.dropdown .dropdown-content > .select:focus {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 0.2rem #eee;
}
