#reset-button-component {
  width: 100%;
}
#reset-button-component .reset-button-content {
  width: 100%;
}
#reset-button-component .reset-button-content button {
  border: none;
  display: inline-block;
  background-color: #9a2376;
  color: #fff;
  border-radius: 5px;
  margin: 10px 0;
  width: 150px;
  padding: 10px;
}
