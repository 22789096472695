#loading-page,
#loading-progress {
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 3500;
  top: 0;
  left: 0;
  float: left;
  background-color: white;
  align-items: center;
  justify-content: center;
}
#loading-page > img,
#loading-progress > img {
  height: 50px;
  width: 50px;
}

@keyframes progressbar-stripes {
  0% {
    background-position: 2rem 0;
  }
  100% {
    background-position: 0 0;
  }
}
#loading-progress {
  height: 30px;
  background-color: #9a2376;
  top: 0px;
  color: white;
  font-weight: bold;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
  background-size: 2rem 2rem;
  -webkit-animation: progressbar-stripes 0.7s linear infinite;
  animation: progressbar-stripes 0.7s linear infinite;
}
