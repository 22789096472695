@keyframes hideMenu {
  to {
    left: 0;
  }
  from {
    left: -300px;
  }
}
@keyframes showMenu {
  to {
    left: -300px;
  }
  from {
    left: 0;
  }
}
@keyframes openContent {
  to {
    width: 100%;
  }
  from {
    width: calc(100% - 300px);
  }
}
@keyframes closeContent {
  to {
    width: calc(100% - 300px);
  }
  from {
    width: 100%;
  }
}
#theme-page > aside.aside-menu {
  width: 300px;
  position: fixed;
  top: 60px;
  float: left;
  left: 0;
  background-color: white;
  height: calc(100vh - 60px);
  z-index: 50;
}
#theme-page > aside.aside-menu.close-animation {
  animation: hideMenu 0.3s;
}
#theme-page > aside.aside-menu.open-animation {
  animation: showMenu 0.3s;
}
#theme-page > aside.aside-menu > .aside-menu-retract {
  height: 40px;
  background-color: white;
  font-size: "16px";
  width: 40px;
  padding: 0;
  position: absolute;
  top: 5px;
  z-index: 100;
  border-radius: 0px 5px 5px 0px;
  left: 300px;
}
#theme-page > article.article-content {
  position: absolute;
  top: 60px;
  float: right;
  right: 0;
  border-radius: var(--radius);
  padding: 10px;
}
#theme-page > article.article-content.open-animation {
  animation: openContent 0.3s;
}
#theme-page > article.article-content.close-animation {
  animation: closeContent 0.3s;
}
#theme-page > article.article-content > .box-enviroment-title {
  padding: 6px 0 12px 50px;
  font-size: 18px;
  font-weight: bold;
}
#theme-page > article.article-content > .box-enviroment-bar {
  border-radius: 5px;
  width: 100%;
  background-color: #dddef3;
  box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 5px;
  padding: 5px;
}
#theme-page > article.article-content > .box-enviroment-bar > .box-align-horizontal {
  display: inline-flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
}
#theme-page > article.article-content > .box-enviroment-bar > .box-align-horizontal > div:nth-child(1) {
  flex: 1;
}
#theme-page > article.article-content > .box-enviroment-bar > .box-align-horizontal > .input-box {
  position: relative;
}
#theme-page > article.article-content > .box-enviroment-bar > .box-align-horizontal > .input-box > .close-search-input {
  position: absolute;
  float: right;
  right: 3px;
  top: 3px;
  background-color: #eee;
  border: solid 1px #ddd;
  border-radius: 5px;
  font-size: 10px;
  text-align: center;
  height: 26px;
  width: 26px;
  line-height: 26px;
  cursor: pointer;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb {
  width: 100%;
  height: 40px;
  margin-top: 5px;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item,
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item-touch {
  height: 36px;
  display: inline-block;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  line-height: 25px;
  text-align: center;
  min-width: 30px;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item.back-folder,
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item-touch.back-folder {
  display: inline-block;
  height: 36px;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item-touch {
  cursor: pointer;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item-touch:hover {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 10px;
}
#theme-page > article.article-content > .box-enviroment-bar > .i-breadcrumb > .i-breadcrumb-item-touch > .btn-back {
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}
#theme-page > article.article-content .box-enviroments-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #fff;
  padding: 5px;
  border-radius: var(--radius);
  margin-bottom: 90px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-radio {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item {
  cursor: pointer;
  display: block;
  box-shadow: 0px 2px 5px #ccc;
  background-color: white;
  margin: 5px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item:hover, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item:hover {
  box-shadow: 0px 5px 5px #ccc;
  transition: 0.3s;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder .more-options, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme .more-options, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder .more-options, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme .more-options {
  position: absolute;
  float: right;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder {
  border-radius: 0 6px 6px 6px;
  border-top: 2px solid #232f9a;
  border-left: 2px solid #232f9a;
  background-color: #e7e7f9;
  position: relative;
  margin-top: 15px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder > .more-options, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder > .more-options {
  right: 5px;
  top: 5px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder > .more-options:hover, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder > .more-options:hover {
  background-color: #c9d2e3;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder::before, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder::before {
  content: "";
  width: 50px;
  height: 12px;
  display: block;
  border-radius: 0 20px 0 0;
  background-color: #232f9a;
  position: absolute;
  top: -12px;
  left: -2px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.folder .description, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.folder .description {
  padding: 12px;
  width: calc(100% - 40px);
  font-size: 14px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme {
  border-top: 2px solid #232f9a;
  outline: none;
  text-decoration: none;
  border-radius: 6px;
  position: relative;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme.dragging, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme.dragging {
  cursor: grabbing !important;
  background-color: white;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme > .more-options, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme > .more-options {
  right: 5px;
  top: 5px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme > .more-options:hover, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme > .more-options:hover {
  background-color: #eee;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme > img, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme > img {
  width: 35px;
  margin: 10px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item.theme > a, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item.theme > a {
  position: absolute;
  top: 10px;
  left: 50px;
  float: left;
  display: block;
  color: #0e157b;
  padding: 5px 10px;
  width: calc(100% - 70px);
  height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  font-size: 14px;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .level, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .level {
  display: block;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  border-top: 2px solid #232f9a;
  box-shadow: 0px 2px 5px #ccc;
  padding: 10px;
  outline: none;
  text-decoration: none;
  color: #232f9a;
  font-weight: 600;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .level:hover, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .level:hover {
  box-shadow: 0px 5px 5px #ccc;
  transition: 0.3s;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .level a, #theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .level a {
  color: #0e157b;
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .level,
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item {
  width: calc(33.3333333% - 10px);
}
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .level,
#theme-page > article.article-content .box-enviroments-content .box-enviroments-content-itens.list .explorer-item {
  width: calc(100% - 10px);
  margin: 5px;
}

@media all and (min-width: 769px) and (max-width: 1080px) {
  #theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item,
#theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .level {
    width: 50%;
  }
}
@media all and (max-width: 1080px) {
  #theme-page .aside-menu {
    box-shadow: rgba(0, 0, 0, 0.2) 5px 0px 5px;
  }
  #theme-page .aside-menu button.aside-menu-retract {
    box-shadow: rgba(0, 0, 0, 0.2) 5px 0px 5px;
  }
}
@media all and (max-width: 768px) {
  #theme-page .article-content .box-enviroment-bar .display-view {
    display: none;
  }
  #theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item,
#theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .level {
    width: 100%;
  }
}
@media all and (min-width: 1280px) {
  #theme-page .article-content .box-enviroment-bar .display-view {
    display: none;
  }
  #theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .explorer-item,
#theme-page .article-content .box-enviroments-content .box-enviroments-content-itens.grid .level {
    width: calc(25% - 10px) !important;
  }
}
#mini-menu-breadcrumb-theme {
  width: 200px;
  padding: 0;
  overflow: hidden;
}
#mini-menu-breadcrumb-theme .mini-menu-item {
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
}
#mini-menu-breadcrumb-theme .mini-menu-item:hover {
  background-color: #eee;
}

div#tree-move-themes {
  border: solid 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
}
div#tree-move-themes:nth-child(2) {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  margin-top: 10px;
}
div#tree-move-themes div.lvl-1 {
  padding-left: 15px;
}
div#tree-move-themes div.lvl-2 {
  padding-left: 30px;
}
div#tree-move-themes div.lvl-3 {
  padding-left: 45px;
}
div#tree-move-themes div.lvl-4 {
  padding-left: 60px;
}
div#tree-move-themes div.lvl-5 {
  padding-left: 75px;
}
div#tree-move-themes div.lvl-6 {
  padding-left: 90px;
}
div#tree-move-themes div.lvl-7 {
  padding-left: 105px;
}
div#tree-move-themes div.lvl-8 {
  padding-left: 120px;
}
div#tree-move-themes div.lvl-9 {
  padding-left: 135px;
}
div#tree-move-themes div.lvl-10 {
  padding-left: 150px;
}
div#tree-move-themes div.lvl-11 {
  padding-left: 165px;
}
div#tree-move-themes div.lvl-12 {
  padding-left: 180px;
}
div#tree-move-themes div.lvl-13 {
  padding-left: 195px;
}
div#tree-move-themes div.lvl-14 {
  padding-left: 210px;
}
div#tree-move-themes div.lvl-15 {
  padding-left: 225px;
}
div#tree-move-themes div.lvl-16 {
  padding-left: 240px;
}
div#tree-move-themes div.lvl-17 {
  padding-left: 255px;
}
div#tree-move-themes div.lvl-18 {
  padding-left: 270px;
}
div#tree-move-themes div.lvl-19 {
  padding-left: 285px;
}
div#tree-move-themes div.lvl-20 {
  padding-left: 300px;
}
div#tree-move-themes div.lvl-21 {
  padding-left: 315px;
}
div#tree-move-themes div.lvl-22 {
  padding-left: 330px;
}
div#tree-move-themes div.lvl-23 {
  padding-left: 345px;
}
div#tree-move-themes div.lvl-24 {
  padding-left: 360px;
}
div#tree-move-themes div.lvl-25 {
  padding-left: 375px;
}
div#tree-move-themes div.lvl-26 {
  padding-left: 390px;
}
div#tree-move-themes div.lvl-27 {
  padding-left: 405px;
}
div#tree-move-themes div.lvl-28 {
  padding-left: 420px;
}
div#tree-move-themes div.lvl-29 {
  padding-left: 435px;
}
div#tree-move-themes div.lvl-30 {
  padding-left: 450px;
}
div#tree-move-themes div.lvl-31 {
  padding-left: 465px;
}
div#tree-move-themes div.lvl-32 {
  padding-left: 480px;
}
div#tree-move-themes div.lvl-33 {
  padding-left: 495px;
}
div#tree-move-themes div.lvl-34 {
  padding-left: 510px;
}
div#tree-move-themes div.lvl-35 {
  padding-left: 525px;
}
div#tree-move-themes div.lvl-36 {
  padding-left: 540px;
}
div#tree-move-themes div.lvl-37 {
  padding-left: 555px;
}
div#tree-move-themes div.lvl-38 {
  padding-left: 570px;
}
div#tree-move-themes div.lvl-39 {
  padding-left: 585px;
}
div#tree-move-themes div.lvl-40 {
  padding-left: 600px;
}
div#tree-move-themes div.lvl-41 {
  padding-left: 615px;
}
div#tree-move-themes div.lvl-42 {
  padding-left: 630px;
}
div#tree-move-themes div.lvl-43 {
  padding-left: 645px;
}
div#tree-move-themes div.lvl-44 {
  padding-left: 660px;
}
div#tree-move-themes div.lvl-45 {
  padding-left: 675px;
}
div#tree-move-themes div.lvl-46 {
  padding-left: 690px;
}
div#tree-move-themes div.lvl-47 {
  padding-left: 705px;
}
div#tree-move-themes div.lvl-48 {
  padding-left: 720px;
}
div#tree-move-themes div.lvl-49 {
  padding-left: 735px;
}
div#tree-move-themes div.lvl-50 {
  padding-left: 750px;
}
div#tree-move-themes div.lvl-51 {
  padding-left: 765px;
}
div#tree-move-themes div.lvl-52 {
  padding-left: 780px;
}
div#tree-move-themes div.lvl-53 {
  padding-left: 795px;
}
div#tree-move-themes div.lvl-54 {
  padding-left: 810px;
}
div#tree-move-themes div.lvl-55 {
  padding-left: 825px;
}
div#tree-move-themes div.lvl-56 {
  padding-left: 840px;
}
div#tree-move-themes div.lvl-57 {
  padding-left: 855px;
}
div#tree-move-themes div.lvl-58 {
  padding-left: 870px;
}
div#tree-move-themes div.lvl-59 {
  padding-left: 885px;
}
div#tree-move-themes div.lvl-60 {
  padding-left: 900px;
}
div#tree-move-themes div.lvl-61 {
  padding-left: 915px;
}
div#tree-move-themes div.lvl-62 {
  padding-left: 930px;
}
div#tree-move-themes div.lvl-63 {
  padding-left: 945px;
}
div#tree-move-themes div.lvl-64 {
  padding-left: 960px;
}
div#tree-move-themes div.lvl-65 {
  padding-left: 975px;
}
div#tree-move-themes div.lvl-66 {
  padding-left: 990px;
}
div#tree-move-themes div.lvl-67 {
  padding-left: 1005px;
}
div#tree-move-themes div.lvl-68 {
  padding-left: 1020px;
}
div#tree-move-themes div.lvl-69 {
  padding-left: 1035px;
}
div#tree-move-themes div.lvl-70 {
  padding-left: 1050px;
}
div#tree-move-themes div.lvl-71 {
  padding-left: 1065px;
}
div#tree-move-themes div.lvl-72 {
  padding-left: 1080px;
}
div#tree-move-themes div.lvl-73 {
  padding-left: 1095px;
}
div#tree-move-themes div.lvl-74 {
  padding-left: 1110px;
}
div#tree-move-themes div.lvl-75 {
  padding-left: 1125px;
}
div#tree-move-themes div.lvl-76 {
  padding-left: 1140px;
}
div#tree-move-themes div.lvl-77 {
  padding-left: 1155px;
}
div#tree-move-themes div.lvl-78 {
  padding-left: 1170px;
}
div#tree-move-themes div.lvl-79 {
  padding-left: 1185px;
}
div#tree-move-themes div.lvl-80 {
  padding-left: 1200px;
}
div#tree-move-themes div.lvl-81 {
  padding-left: 1215px;
}
div#tree-move-themes div.lvl-82 {
  padding-left: 1230px;
}
div#tree-move-themes div.lvl-83 {
  padding-left: 1245px;
}
div#tree-move-themes div.lvl-84 {
  padding-left: 1260px;
}
div#tree-move-themes div.lvl-85 {
  padding-left: 1275px;
}
div#tree-move-themes div.lvl-86 {
  padding-left: 1290px;
}
div#tree-move-themes div.lvl-87 {
  padding-left: 1305px;
}
div#tree-move-themes div.lvl-88 {
  padding-left: 1320px;
}
div#tree-move-themes div.lvl-89 {
  padding-left: 1335px;
}
div#tree-move-themes div.lvl-90 {
  padding-left: 1350px;
}
div#tree-move-themes div.lvl-91 {
  padding-left: 1365px;
}
div#tree-move-themes div.lvl-92 {
  padding-left: 1380px;
}
div#tree-move-themes div.lvl-93 {
  padding-left: 1395px;
}
div#tree-move-themes div.lvl-94 {
  padding-left: 1410px;
}
div#tree-move-themes div.lvl-95 {
  padding-left: 1425px;
}
div#tree-move-themes div.lvl-96 {
  padding-left: 1440px;
}
div#tree-move-themes div.lvl-97 {
  padding-left: 1455px;
}
div#tree-move-themes div.lvl-98 {
  padding-left: 1470px;
}
div#tree-move-themes div.lvl-99 {
  padding-left: 1485px;
}
div#tree-move-themes div.lvl-100 {
  padding-left: 1500px;
}
div#tree-move-themes div.open {
  display: block;
}
div#tree-move-themes div.close {
  display: none;
}
div#tree-move-themes div.tree-list-item {
  cursor: pointer;
  border-radius: 3px;
}
div#tree-move-themes div.tree-list-item:hover {
  background-color: #ddd;
}
div#tree-move-themes div.tree-list-item div.icon,
div#tree-move-themes div.tree-list-item div.name {
  display: inline-block;
  line-height: 30px;
  height: 30px;
}
div#tree-move-themes div.tree-list-item div.name {
  margin-left: 5px;
  width: calc(100% - 40px);
}
div#tree-move-themes div.tree-list-item div.icon {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
}
div#tree-move-themes div.tree-list-item div.icon:hover {
  background-color: #ccc;
}
div#tree-move-themes div.tree-list-item.checked {
  background-color: var(--color-inovyo-dark);
  color: #fff;
}
div#tree-move-themes div.tree-list-item.checked > div.icon:hover {
  background-color: var(--color-inovyo-light);
}
