#google-auth-page .error-box,
#google-auth-page .error-icon,
#google-auth-page .error-btn {
  margin: auto;
  width: 320px;
  text-align: center;
}
#google-auth-page .error-box {
  font-size: 26px;
  color: #920286;
  font-weight: bold;
}
#google-auth-page .error-icon {
  font-size: 60px;
  margin-top: 150px;
  color: #927802;
}
#google-auth-page .error-btn {
  margin-top: 50px;
}
