#image-upload-component.image-upload {
  width: 100%;
}
#image-upload-component.image-upload .image-upload-title {
  margin: 20px 0;
  position: relative;
}
#image-upload-component.image-upload .image-upload-content {
  padding: 10px;
  width: 100%;
  background-color: #ccc;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin: 10px 0;
  line-height: 50%;
  border-radius: 5px;
}
#image-upload-component.image-upload .image-upload-content svg {
  font-size: 30px;
}
