#notfound-page {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
#notfound-page span {
  display: block;
  margin-top: 150px;
  color: var(--color-inovyo);
  font-size: 40px;
}
