#content-tree-inovyo {
  padding: 10px;
  height: calc(100vh - 60px);
}
#content-tree-inovyo > div.tree-list {
  border: solid 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
}
#content-tree-inovyo > div.tree-list:nth-child(2) {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  margin-top: 10px;
}
#content-tree-inovyo > div.tree-list div.lvl-1 {
  padding-left: 15px;
}
#content-tree-inovyo > div.tree-list div.lvl-2 {
  padding-left: 30px;
}
#content-tree-inovyo > div.tree-list div.lvl-3 {
  padding-left: 45px;
}
#content-tree-inovyo > div.tree-list div.lvl-4 {
  padding-left: 60px;
}
#content-tree-inovyo > div.tree-list div.lvl-5 {
  padding-left: 75px;
}
#content-tree-inovyo > div.tree-list div.lvl-6 {
  padding-left: 90px;
}
#content-tree-inovyo > div.tree-list div.lvl-7 {
  padding-left: 105px;
}
#content-tree-inovyo > div.tree-list div.lvl-8 {
  padding-left: 120px;
}
#content-tree-inovyo > div.tree-list div.lvl-9 {
  padding-left: 135px;
}
#content-tree-inovyo > div.tree-list div.lvl-10 {
  padding-left: 150px;
}
#content-tree-inovyo > div.tree-list div.lvl-11 {
  padding-left: 165px;
}
#content-tree-inovyo > div.tree-list div.lvl-12 {
  padding-left: 180px;
}
#content-tree-inovyo > div.tree-list div.lvl-13 {
  padding-left: 195px;
}
#content-tree-inovyo > div.tree-list div.lvl-14 {
  padding-left: 210px;
}
#content-tree-inovyo > div.tree-list div.lvl-15 {
  padding-left: 225px;
}
#content-tree-inovyo > div.tree-list div.lvl-16 {
  padding-left: 240px;
}
#content-tree-inovyo > div.tree-list div.lvl-17 {
  padding-left: 255px;
}
#content-tree-inovyo > div.tree-list div.lvl-18 {
  padding-left: 270px;
}
#content-tree-inovyo > div.tree-list div.lvl-19 {
  padding-left: 285px;
}
#content-tree-inovyo > div.tree-list div.lvl-20 {
  padding-left: 300px;
}
#content-tree-inovyo > div.tree-list div.lvl-21 {
  padding-left: 315px;
}
#content-tree-inovyo > div.tree-list div.lvl-22 {
  padding-left: 330px;
}
#content-tree-inovyo > div.tree-list div.lvl-23 {
  padding-left: 345px;
}
#content-tree-inovyo > div.tree-list div.lvl-24 {
  padding-left: 360px;
}
#content-tree-inovyo > div.tree-list div.lvl-25 {
  padding-left: 375px;
}
#content-tree-inovyo > div.tree-list div.lvl-26 {
  padding-left: 390px;
}
#content-tree-inovyo > div.tree-list div.lvl-27 {
  padding-left: 405px;
}
#content-tree-inovyo > div.tree-list div.lvl-28 {
  padding-left: 420px;
}
#content-tree-inovyo > div.tree-list div.lvl-29 {
  padding-left: 435px;
}
#content-tree-inovyo > div.tree-list div.lvl-30 {
  padding-left: 450px;
}
#content-tree-inovyo > div.tree-list div.lvl-31 {
  padding-left: 465px;
}
#content-tree-inovyo > div.tree-list div.lvl-32 {
  padding-left: 480px;
}
#content-tree-inovyo > div.tree-list div.lvl-33 {
  padding-left: 495px;
}
#content-tree-inovyo > div.tree-list div.lvl-34 {
  padding-left: 510px;
}
#content-tree-inovyo > div.tree-list div.lvl-35 {
  padding-left: 525px;
}
#content-tree-inovyo > div.tree-list div.lvl-36 {
  padding-left: 540px;
}
#content-tree-inovyo > div.tree-list div.lvl-37 {
  padding-left: 555px;
}
#content-tree-inovyo > div.tree-list div.lvl-38 {
  padding-left: 570px;
}
#content-tree-inovyo > div.tree-list div.lvl-39 {
  padding-left: 585px;
}
#content-tree-inovyo > div.tree-list div.lvl-40 {
  padding-left: 600px;
}
#content-tree-inovyo > div.tree-list div.lvl-41 {
  padding-left: 615px;
}
#content-tree-inovyo > div.tree-list div.lvl-42 {
  padding-left: 630px;
}
#content-tree-inovyo > div.tree-list div.lvl-43 {
  padding-left: 645px;
}
#content-tree-inovyo > div.tree-list div.lvl-44 {
  padding-left: 660px;
}
#content-tree-inovyo > div.tree-list div.lvl-45 {
  padding-left: 675px;
}
#content-tree-inovyo > div.tree-list div.lvl-46 {
  padding-left: 690px;
}
#content-tree-inovyo > div.tree-list div.lvl-47 {
  padding-left: 705px;
}
#content-tree-inovyo > div.tree-list div.lvl-48 {
  padding-left: 720px;
}
#content-tree-inovyo > div.tree-list div.lvl-49 {
  padding-left: 735px;
}
#content-tree-inovyo > div.tree-list div.lvl-50 {
  padding-left: 750px;
}
#content-tree-inovyo > div.tree-list div.lvl-51 {
  padding-left: 765px;
}
#content-tree-inovyo > div.tree-list div.lvl-52 {
  padding-left: 780px;
}
#content-tree-inovyo > div.tree-list div.lvl-53 {
  padding-left: 795px;
}
#content-tree-inovyo > div.tree-list div.lvl-54 {
  padding-left: 810px;
}
#content-tree-inovyo > div.tree-list div.lvl-55 {
  padding-left: 825px;
}
#content-tree-inovyo > div.tree-list div.lvl-56 {
  padding-left: 840px;
}
#content-tree-inovyo > div.tree-list div.lvl-57 {
  padding-left: 855px;
}
#content-tree-inovyo > div.tree-list div.lvl-58 {
  padding-left: 870px;
}
#content-tree-inovyo > div.tree-list div.lvl-59 {
  padding-left: 885px;
}
#content-tree-inovyo > div.tree-list div.lvl-60 {
  padding-left: 900px;
}
#content-tree-inovyo > div.tree-list div.lvl-61 {
  padding-left: 915px;
}
#content-tree-inovyo > div.tree-list div.lvl-62 {
  padding-left: 930px;
}
#content-tree-inovyo > div.tree-list div.lvl-63 {
  padding-left: 945px;
}
#content-tree-inovyo > div.tree-list div.lvl-64 {
  padding-left: 960px;
}
#content-tree-inovyo > div.tree-list div.lvl-65 {
  padding-left: 975px;
}
#content-tree-inovyo > div.tree-list div.lvl-66 {
  padding-left: 990px;
}
#content-tree-inovyo > div.tree-list div.lvl-67 {
  padding-left: 1005px;
}
#content-tree-inovyo > div.tree-list div.lvl-68 {
  padding-left: 1020px;
}
#content-tree-inovyo > div.tree-list div.lvl-69 {
  padding-left: 1035px;
}
#content-tree-inovyo > div.tree-list div.lvl-70 {
  padding-left: 1050px;
}
#content-tree-inovyo > div.tree-list div.lvl-71 {
  padding-left: 1065px;
}
#content-tree-inovyo > div.tree-list div.lvl-72 {
  padding-left: 1080px;
}
#content-tree-inovyo > div.tree-list div.lvl-73 {
  padding-left: 1095px;
}
#content-tree-inovyo > div.tree-list div.lvl-74 {
  padding-left: 1110px;
}
#content-tree-inovyo > div.tree-list div.lvl-75 {
  padding-left: 1125px;
}
#content-tree-inovyo > div.tree-list div.lvl-76 {
  padding-left: 1140px;
}
#content-tree-inovyo > div.tree-list div.lvl-77 {
  padding-left: 1155px;
}
#content-tree-inovyo > div.tree-list div.lvl-78 {
  padding-left: 1170px;
}
#content-tree-inovyo > div.tree-list div.lvl-79 {
  padding-left: 1185px;
}
#content-tree-inovyo > div.tree-list div.lvl-80 {
  padding-left: 1200px;
}
#content-tree-inovyo > div.tree-list div.lvl-81 {
  padding-left: 1215px;
}
#content-tree-inovyo > div.tree-list div.lvl-82 {
  padding-left: 1230px;
}
#content-tree-inovyo > div.tree-list div.lvl-83 {
  padding-left: 1245px;
}
#content-tree-inovyo > div.tree-list div.lvl-84 {
  padding-left: 1260px;
}
#content-tree-inovyo > div.tree-list div.lvl-85 {
  padding-left: 1275px;
}
#content-tree-inovyo > div.tree-list div.lvl-86 {
  padding-left: 1290px;
}
#content-tree-inovyo > div.tree-list div.lvl-87 {
  padding-left: 1305px;
}
#content-tree-inovyo > div.tree-list div.lvl-88 {
  padding-left: 1320px;
}
#content-tree-inovyo > div.tree-list div.lvl-89 {
  padding-left: 1335px;
}
#content-tree-inovyo > div.tree-list div.lvl-90 {
  padding-left: 1350px;
}
#content-tree-inovyo > div.tree-list div.lvl-91 {
  padding-left: 1365px;
}
#content-tree-inovyo > div.tree-list div.lvl-92 {
  padding-left: 1380px;
}
#content-tree-inovyo > div.tree-list div.lvl-93 {
  padding-left: 1395px;
}
#content-tree-inovyo > div.tree-list div.lvl-94 {
  padding-left: 1410px;
}
#content-tree-inovyo > div.tree-list div.lvl-95 {
  padding-left: 1425px;
}
#content-tree-inovyo > div.tree-list div.lvl-96 {
  padding-left: 1440px;
}
#content-tree-inovyo > div.tree-list div.lvl-97 {
  padding-left: 1455px;
}
#content-tree-inovyo > div.tree-list div.lvl-98 {
  padding-left: 1470px;
}
#content-tree-inovyo > div.tree-list div.lvl-99 {
  padding-left: 1485px;
}
#content-tree-inovyo > div.tree-list div.lvl-100 {
  padding-left: 1500px;
}
#content-tree-inovyo > div.tree-list div.open {
  display: block;
}
#content-tree-inovyo > div.tree-list div.close {
  display: none;
}
#content-tree-inovyo > div.tree-list div.tree-list-item {
  cursor: pointer;
  border-radius: 3px;
}
#content-tree-inovyo > div.tree-list div.tree-list-item:hover {
  background-color: #ddd;
}
#content-tree-inovyo > div.tree-list div.tree-list-item.dragging {
  background-color: #ccc;
  border: dotted 2px var(--color-inovyo);
  cursor: grabbing;
}
#content-tree-inovyo > div.tree-list div.tree-list-item.add {
  background-color: var(--color-inovyo);
  color: white;
  margin: 5px;
}
#content-tree-inovyo > div.tree-list div.tree-list-item.add:hover {
  background-color: var(--color-inovyo-light);
}
#content-tree-inovyo > div.tree-list div.tree-list-item div.icon,
#content-tree-inovyo > div.tree-list div.tree-list-item div.name {
  display: inline-block;
  line-height: 30px;
  height: 30px;
}
#content-tree-inovyo > div.tree-list div.tree-list-item div.name {
  margin-left: 5px;
  width: calc(100% - 40px);
}
#content-tree-inovyo > div.tree-list div.tree-list-item div.name.dragover {
  background-color: var(--color-inovyo-light);
  border-radius: 3px;
  color: #fff;
}
#content-tree-inovyo > div.tree-list div.tree-list-item div.icon {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 3px;
}
#content-tree-inovyo > div.tree-list div.tree-list-item div.icon:hover {
  background-color: #ccc;
}
#content-tree-inovyo > div.tree-list div.tree-list-item.checked {
  background-color: #efd7e8;
  color: var(--color-inovyo-dark);
}
#content-tree-inovyo > div.tree-list div.tree-list-item.checked > div.icon:hover {
  background-color: #e6b6d7;
}
#content-tree-inovyo > span.version {
  display: block;
  width: 100%;
  text-align: center;
  padding: var(--padding);
  font-size: 12px;
  color: #aaa;
}
