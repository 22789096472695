#csat-component {
  /*CSAT Design*/
}
#csat-component.csat, #csat-component.csat-colorfull, #csat-component.csat-shadow-colorfull, #csat-component.csat-shadow-color, #csat-component.csat-shadow-like, #csat-component.csat-shadow-heart, #csat-component.csat-emoticon, #csat-component.csat-emoticon-colorfull, #csat-component.csat-emoticon-3d, #csat-component.csat-stars, #csat-component.csat-stars-3d, #csat-component.csat-stars-3d-animated, #csat-component.faces-3, #csat-component.csat3, #csat-component.csat-heart {
  width: 100%;
}
#csat-component.csat .csat-question, #csat-component.csat-colorfull .csat-question, #csat-component.csat-shadow-colorfull .csat-question, #csat-component.csat-shadow-color .csat-question, #csat-component.csat-shadow-like .csat-question, #csat-component.csat-shadow-heart .csat-question, #csat-component.csat-emoticon .csat-question, #csat-component.csat-emoticon-colorfull .csat-question, #csat-component.csat-emoticon-3d .csat-question, #csat-component.csat-stars .csat-question, #csat-component.csat-stars-3d .csat-question, #csat-component.csat-stars-3d-animated .csat-question, #csat-component.faces-3 .csat-question, #csat-component.csat3 .csat-question, #csat-component.csat-heart .csat-question {
  margin: 20px 0;
  width: 100%;
  position: relative;
}
#csat-component.csat .csat-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}
#csat-component.csat .csat-content .csat-item {
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: flex;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  color: #222;
  font-weight: bold;
  font-size: 18px;
  border: solid 1px #949494;
  justify-content: center;
  align-items: center;
}
#csat-component.standard .csat-content-standard {
  display: flex;
  flex-direction: column;
}
#csat-component.standard .csat-content-standard .numbers,
#csat-component.standard .csat-content-standard .radio {
  display: flex;
  flex-direction: row;
}
#csat-component.standard .csat-content-standard .numbers span,
#csat-component.standard .csat-content-standard .radio span {
  display: flex;
  width: 40px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex: 1;
}
#csat-component.standard .csat-content-standard .radio span {
  height: 40px;
}
#csat-component.standard .csat-content-standard .radio span input[type=radio] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
#csat-component.standard .csat-content-standard .radio span input[type=radio] + label {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
}
#csat-component.standard .csat-content-standard .radio span input[type=radio] + label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: -4px;
  left: 0;
  bottom: 0;
  border: solid 2px black;
  vertical-align: bottom;
  border-radius: 50%;
}
#csat-component.standard .csat-content-standard .radio span input[type=radio]:checked + label::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 4px;
  width: 10px;
  height: 10px;
  top: 1px;
  background-color: black;
  border-radius: 50%;
}
#csat-component.rounded .csat-content .csat-item {
  border-radius: 50%;
  width: 50px;
}
#csat-component.colorfull .csat-content .csat-item.option-color-0 {
  background-color: #e7e7e7;
}
#csat-component.colorfull .csat-content .csat-item.option-color-1 {
  background-color: #ffc8c9;
}
#csat-component.colorfull .csat-content .csat-item.option-color-2 {
  background-color: #ffe1bd;
}
#csat-component.colorfull .csat-content .csat-item.option-color-3 {
  background-color: #fff0c1;
}
#csat-component.colorfull .csat-content .csat-item.option-color-4 {
  background-color: #fbffc0;
}
#csat-component.colorfull .csat-content .csat-item.option-color-5 {
  background-color: #d4ffbe;
}
#csat-component.shadow .csat-content .csat-item {
  box-shadow: 3px 3px 0 0 #414141;
}
#csat-component.emoticon .csat-content .csat-item {
  border: none;
}
#csat-component.emoticon .csat-content .csat-item.option-color-0, #csat-component.emoticon .csat-content .csat-item.option-color-1, #csat-component.emoticon .csat-content .csat-item.option-color-2, #csat-component.emoticon .csat-content .csat-item.option-color-3, #csat-component.emoticon .csat-content .csat-item.option-color-4, #csat-component.emoticon .csat-content .csat-item.option-color-5 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
  background-color: #fff;
  opacity: 0.8;
}
#csat-component.emoticon .csat-content .csat-item.option-color-0 {
  background-image: url("../../images/survey/emoticon-na-shadow.svg");
}
#csat-component.emoticon .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/emoticon-very-bad-shadow.svg");
}
#csat-component.emoticon .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/emoticon-bad-shadow.svg");
}
#csat-component.emoticon .csat-content .csat-item.option-color-3 {
  background-image: url("../../images/survey/emoticon-neutro-shadow.svg");
}
#csat-component.emoticon .csat-content .csat-item.option-color-4 {
  background-image: url("../../images/survey/emoticon-happy-shadow.svg");
}
#csat-component.emoticon .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/emoticon-very-happy-shadow.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item {
  border: none;
}
#csat-component.emoticon-colorfull .csat-content .csat-item:hover {
  scale: 1.1;
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-0, #csat-component.emoticon-colorfull .csat-content .csat-item.option-color-1, #csat-component.emoticon-colorfull .csat-content .csat-item.option-color-2, #csat-component.emoticon-colorfull .csat-content .csat-item.option-color-3, #csat-component.emoticon-colorfull .csat-content .csat-item.option-color-4, #csat-component.emoticon-colorfull .csat-content .csat-item.option-color-5 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
  background-color: #fff;
  opacity: 0.5;
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-0 {
  background-image: url("../../images/survey/emoticon-na-light.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/emoticon-very-bad-light.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/emoticon-bad-light.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-3 {
  background-image: url("../../images/survey/emoticon-neutro-light.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-4 {
  background-image: url("../../images/survey/emoticon-happy-light.svg");
}
#csat-component.emoticon-colorfull .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/emoticon-very-happy-light.svg");
}
#csat-component.emoticon-3d .csat-content .csat-item {
  border: none;
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-0, #csat-component.emoticon-3d .csat-content .csat-item.option-color-1, #csat-component.emoticon-3d .csat-content .csat-item.option-color-2, #csat-component.emoticon-3d .csat-content .csat-item.option-color-3, #csat-component.emoticon-3d .csat-content .csat-item.option-color-4, #csat-component.emoticon-3d .csat-content .csat-item.option-color-5 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  background-size: contain;
  opacity: 0.5;
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-0 {
  background-image: url("../../images/survey/emoticon-3d-na1.png");
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/emoticon-3d-01.png");
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/emoticon-3d-02.png");
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-3 {
  background-image: url("../../images/survey/emoticon-3d-03.png");
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-4 {
  background-image: url("../../images/survey/emoticon-3d-04.png");
}
#csat-component.emoticon-3d .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/emoticon-3d-05.png");
}
#csat-component.stars .csat-content .csat-item {
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
}
#csat-component.stars .csat-content .csat-item.option-color-1, #csat-component.stars .csat-content .csat-item.option-color-2, #csat-component.stars .csat-content .csat-item.option-color-3, #csat-component.stars .csat-content .csat-item.option-color-4, #csat-component.stars .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/star-disable.svg");
  opacity: 0.5;
}
#csat-component.stars .csat-content .csat-item.option-color-0 {
  opacity: 0.5;
  background-image: url("../../images/survey/star-na-disable.svg");
}
#csat-component.stars-3d .csat-content .csat-item {
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.8;
}
#csat-component.stars-3d .csat-content .csat-item.option-color-1, #csat-component.stars-3d .csat-content .csat-item.option-color-2, #csat-component.stars-3d .csat-content .csat-item.option-color-3, #csat-component.stars-3d .csat-content .csat-item.option-color-4, #csat-component.stars-3d .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/star-3d-disable.svg");
}
#csat-component.stars-3d .csat-content .csat-item.option-color-0 {
  background-image: url("../../images/survey/star-3d-na-disable.svg");
}
#csat-component.stars-3d-animated .csat-content .csat-item {
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.8;
}
#csat-component.stars-3d-animated .csat-content .csat-item.option-color-1, #csat-component.stars-3d-animated .csat-content .csat-item.option-color-2, #csat-component.stars-3d-animated .csat-content .csat-item.option-color-3, #csat-component.stars-3d-animated .csat-content .csat-item.option-color-4, #csat-component.stars-3d-animated .csat-content .csat-item.option-color-5 {
  background-image: url("../../images/survey/star-animated-disabled.png");
}
#csat-component.stars-3d-animated .csat-content .csat-item.option-color-0 {
  background-image: url("../../images/survey/star-animated-na-disabled.png");
}
#csat-component.faces-3 .csat-content .csat-item {
  cursor: pointer;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.5;
  text-align: center;
}
#csat-component.faces-3 .csat-content .csat-item.option-color-1, #csat-component.faces-3 .csat-content .csat-item.option-color-2, #csat-component.faces-3 .csat-content .csat-item.option-color-3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  background-color: #fff;
}
#csat-component.faces-3 .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/emoticon-very-bad-shadow.svg");
}
#csat-component.faces-3 .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/emoticon-neutro-shadow.svg");
}
#csat-component.faces-3 .csat-content .csat-item.option-color-3 {
  background-image: url("../../images/survey/emoticon-very-happy-shadow.svg");
}
#csat-component.like-dislike .csat-content .csat-item {
  cursor: pointer;
  display: inline-block;
  width: 45%;
  margin: 2px;
  height: 80px;
  border-radius: 5px;
  text-align: center;
  line-height: 80px;
  color: #222;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  opacity: 0.5;
}
#csat-component.like-dislike .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/like-hands-down.svg");
}
#csat-component.like-dislike .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/like-hands-up.svg");
}
#csat-component.love-dislove .csat-content .csat-item {
  cursor: pointer;
  display: inline-block;
  width: 45%;
  margin: 2px;
  height: 80px;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 80px;
  color: #222;
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  opacity: 0.5;
}
#csat-component.love-dislove .csat-content .csat-item.option-color-1 {
  background-image: url("../../images/survey/heart-broke.svg");
}
#csat-component.love-dislove .csat-content .csat-item.option-color-2 {
  background-image: url("../../images/survey/heart.svg");
}
