@charset "UTF-8";
:root {
  --color-inovyo: #9a2376;
  --color-inovyo-light: #b44292;
  --color-inovyo-dark: #7b0e5a;
  --color-bg: #f0f2f5;
  --color-inovyo-green: #009045;
  --color-inovyo-yellow: #ffc400;
  --color-inovyo-yellow-light: #ffd23e;
  --color-inovyo-yellow-dark: #d1a100;
  --radius: 8px;
  --radius-large: 12px;
  --padding-small: 5px;
  --padding: 10px;
  --padding-large: 15px;
  --max-width: 1024px;
  --max-width-mobile: 768px;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-button { } */
::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-track-piece {} */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
}

/* ::-webkit-scrollbar-corner {} */
/* ::-webkit-resizer {} */
*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::selection {
  background-color: #3f3f3f;
  color: #ffffff;
}

body {
  font-family: "Nunito Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #444;
  overflow-y: auto;
  background-color: var(--color-bg);
}

ul {
  list-style: none;
}

/* Modais sem botoões no footer */
.buttons-modal-no-footer {
  width: 100%;
  padding: 10px 0 0 0;
  display: inline-flex;
  justify-content: flex-end;
  column-gap: 12px;
  row-gap: 12px;
}

body.christmas {
  background-image: url("../images/mel-poole-LUPXhXj2ip0-unsplash-.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
