#theme-editor-page {
  margin: 10px;
  display: flex;
  max-width: 1440px;
  flex-direction: column;
  width: 100%;
  margin: auto;
}
#theme-editor-page > .main-edition {
  height: calc(100vh - 140px);
  display: flex;
  width: 100%;
}
#theme-editor-page > .main-edition > .box-left,
#theme-editor-page > .main-edition > .box-right {
  width: 50%;
  background-color: white;
  height: calc(100% - 10px);
  padding: 5px;
}
#theme-editor-page > .main-edition > .box-left .theme-title,
#theme-editor-page > .main-edition > .box-right .theme-title {
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
#theme-editor-page > .main-edition > .box-left {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-right: solid 1px #eee;
}
#theme-editor-page > .main-edition > .box-left > .content-editor {
  margin-top: 5px;
  padding: 8px;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .one-box,
#theme-editor-page > .main-edition > .box-left > .content-editor > .side-by-side {
  margin-bottom: 15px;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .one-box > label.label-input {
  padding: 5px 10px;
  clear: both;
  display: block;
  width: 100%;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .side-by-side {
  display: flex;
  gap: 5px;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .side-by-side > div {
  width: 50%;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .side-by-side > div > label.label-input {
  padding: 5px 10px;
  clear: both;
  display: block;
  width: 100%;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .three-parties {
  width: 100%;
  margin-top: 5px;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .three-parties label {
  padding: 10px 0;
  display: block;
}
#theme-editor-page > .main-edition > .box-left > .content-editor > .three-parties span {
  display: block;
  font-size: 13px;
  color: #888;
  width: calc(100% - 1px);
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}
#theme-editor-page > .main-edition > .box-right {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
#theme-editor-page > .main-edition > .box-right > .preview-box {
  border: solid 1px #eee;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
  height: calc(100% - 40px);
  overflow: hidden auto;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .header {
  height: 80px;
  width: 100%;
  background-color: white;
  z-index: 2;
  position: sticky;
  top: 0;
  float: left;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .body {
  height: calc(100% + 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer {
  height: auto;
  width: 100%;
  background-color: #f5f5f5;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 0;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  gap: 10px;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-buttons button.button-survey {
  border: none;
  display: inline-block;
  background-color: #9a2376;
  color: #fff;
  border-radius: 5px;
  width: 150px;
  padding: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-buttons button.button-survey:hover {
  background-color: #9a2376cc;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-buttons button.button-survey:active {
  background-color: #9a2376;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-progress .static-bar {
  width: 300px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  margin: 5px auto;
  border-radius: 10px;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-progress .static-bar .bar {
  float: left;
  min-width: 25px;
  max-width: 296px;
  height: 16px;
  margin: 1px;
  border-radius: 8px;
  color: white;
  line-height: 16px;
  font-size: 12px;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-progress .steps-bar {
  width: 150px;
  height: 25px;
  border-width: 1px;
  border-style: solid;
  margin: 5px auto;
  border-radius: 15px;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-progress .steps-bar .bar {
  width: 50%;
  height: 21px;
  margin: 1px auto;
  border-radius: 12px;
  color: white;
  line-height: 21px;
  font-size: 12px;
}
#theme-editor-page > .main-edition > .box-right > .preview-box > .footer .box-poweredby {
  width: 100%;
  font-size: 10px;
  padding: 5px 0 0 0;
}
#theme-editor-page > .box-save {
  height: 50px;
  width: 100%;
  background-color: #fff;
  border-radius: var(--radius);
  padding: 8px;
}
#theme-editor-page > .box-save > button {
  margin-right: 10px;
}
#theme-editor-page > .box-save > span {
  background-color: #ff7340;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: var(--radius);
  color: white;
}
